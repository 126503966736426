
// Common
import { default as init } from "./common/base";

// Helpers
import { Component } from "./helpers/component";

// Components
import { Slider } from "../../components/slider/slider";
import { Menu } from "../../components/menu/menu";

Component.register("Slider", Slider);
Component.register("Menu", Menu);


init();

document.addEventListener('DOMContentLoaded', () => {


  var vW = $(window).width();
  var str = $('.c-footer__content--num').text();
  var num = str.replace(/\s+/g, '');

  if (vW > 992) {
    $('a[data-value="Prenota"]').attr('href', 'https://wa.me/' + num );
  }
  else {
    $('a[data-value="Prenota"]').attr('href', 'tel:+39' + num );
  }

  // $('a[data-value="Prenota"]').click(function(){
  //   var vW = $(window).width();
  //   if (vW > 992) {
  //     if ( !$(this).hasClass('active') ){
  //       $(this).attr('href', 'https://wa.me/3429505765');
  //       $(this).addClass('active')
  //     }
  //     else {
  //       $(this).removeClass('active');
  //     }
  //   }
  //   else {
  //     $(this).attr('href', 'tel:+393429505765');
  //   }
  // });

  var viewportWidth = $(window).width();

$('a[data-value="About"]').click(function(){
    event.preventDefault();
    var offset = $('#About').offset();
    if (viewportWidth < 992) {
      var scrollto = offset.top - 60;
    }
    else{
    var scrollto = offset.top - 96
  }

    $('html, body').animate({scrollTop:scrollto}, 1000);

});

$('a[data-value="Food"]').click(function(){
  event.preventDefault();
  var offset = $('#Food').offset();
  if (viewportWidth < 992) {
    var scrollto = offset.top - 60;
  }
  else{
  var scrollto = offset.top - 96
}

  $('html, body').animate({scrollTop:scrollto}, 1000);
});

$('a[data-value="Drink"]').click(function(){
  event.preventDefault();
  var offset = $('#Drink').offset();
  if (viewportWidth < 992) {
    var scrollto = offset.top - 40;
  }
  else{
  var scrollto = offset.top - 96
}

  $('html, body').animate({scrollTop:scrollto}, 1000);
});

$('a[data-value="Occasioni"]').click(function(){
  event.preventDefault();
  var offset = $('#Occasioni').offset();
  if (viewportWidth < 992) {
    var scrollto = offset.top - 40;
  }
  else{
  var scrollto = offset.top - 96
}

  $('html, body').animate({scrollTop:scrollto}, 1000);
});


$( window ).resize(function() {
  var vW = $(window).width();
  if (vW > 992) {
    $('a[data-value="Prenota"]').attr('href', 'https://wa.me/' + num);
  }
  else {
    $('a[data-value="Prenota"]').attr('href', 'tel:+39' + num);
  }
});

});

